
export const BASE_SCAN_URLS = {
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com',
  31337: 'https://testnet.bscscan.com',
}

export const API_HOST = process.env.VUE_APP_API_HOST || window.location.protocol+"//"+window.location.host+"/api"

export const MAX_INT = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
